<template>

  <b-modal ref="modal" v-model="showBatchOptionsModal" hide-footer>
    <b-row v-if="showError">
      <b-col lg="12">
        <b-alert variant="danger" show>{{ errorText }}</b-alert>
      </b-col>
    </b-row>

    <b-card title="Vänligen vänta" v-if="waiting">

      <b-row style="height: 20px; text-align: center;">
        <b-col lg="12">
          <div class="spinner spinner-track spinner-primary spinner-lg mr-15"></div>
        </b-col>
      </b-row>

      <b-row>
        <b-col>
          <p>{{ $t('COMMON.WAITING') }}...</p>
        </b-col>
      </b-row>

    </b-card>

    <div v-if="!waiting">
      <b-row>
        <b-col cols="12">
          <h3 v-if="type === 'email'">{{ $t('PAGES.SENDOUT.EMAIL_SENDOUT') }}</h3>
          <h3 v-if="type === 'sms'">{{ $t('PAGES.SENDOUT.SMS_SENDOUT') }}</h3>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-form-group id="input-group-6" :label="$t('COMMON.SELECTION')" label-for="input-6">
            <div class="radio-list mt-4">
              <label class="radio radio-outline radio-success mb-4">
                <input type="radio" v-model="selection_option" value="FILTERED" name="type" />
                <span></span>
                {{ $t('SHOP_ORDER.BASED_ON_FILTER') }}
              </label>

              <label class="radio radio-outline radio-success mb-4">
                <input type="radio" v-model="selection_option" value="SELECTION" name="type" />
                <span></span>
                {{ $t('SHOP_ORDER.SELECTED_IN_TABLE') }}
              </label>
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row v-if="selection_option === 'FILTERED'">
        <b-col cols="12">
          <b-form-group id="input-group-8" :label="$t('SHOP_ORDER.CREATED_FROM')" label-for="input-8">
            <b-form-input autocomplete="off" v-model="created_from_str" :disabled="true" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="input-group-9" :label="$t('SHOP_ORDER.CREATED_TO')" label-for="input-9">
            <b-form-input autocomplete="off" v-model="created_to_str" :disabled="true" />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group id="input-group-10" :label="$t('SHOP_ORDER.STATUSES')" label-for="input-10">
            <b-form-input autocomplete="off" v-model="statuses_str" :disabled="true" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6">
          <b-form-group id="input-group-7" :label="$t('SHOP_ORDER.COUNT')" label-for="input-7">
            <b-form-input autocomplete="off" v-model="count" :disabled="true" />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row class="mt-6">
        <b-col lg="6">
          <b-button variant="outline-success" @click="save">
            {{ $t('PAGES.JOBS.CREATE') }}
          </b-button>
        </b-col>
        <b-col lg="6">
          <b-button variant="outline-primary" @click="cancel">
            {{ $t('COMMON.CANCEL') }}
          </b-button>
        </b-col>
      </b-row>
    </div>
  </b-modal>

</template>

<script>

import axios from 'axios';

import Confirm from '@/view/components/Confirm.vue';
import { mapGetters } from 'vuex';
import { format_sek } from '@/core/services/currency';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'shop-order-send-link-modal',
  mixins: [ toasts ],
  components: {
    Confirm
  },
  data() {
    return {
      fetch_count: 0,
      selection_option: 'SELECTION',
      waiting: false,
      showError: false,
      errorText: this.$t('COMMON.AN_ERROR_OCCURED'),
      showBatchOptionsModal: false,
      renew_ids: [],
      template: null
    };
  },
  props: ['created_from','created_to','statuses','shop_order_ids'],
  emits: ['cancel'],
  watch: {
    selection_option(newValue) {
      this.get_shop_order_ids_from_option(newValue);
    },

  },
  computed: {
    created_from_str() {
      return this.created_from ? this.created_from : this.$t('SHOP_ORDER.ANY_DATE');
    },
    created_to_str() {
      return this.created_to ? this.created_to : this.$t('SHOP_ORDER.ANY_DATE');
    },
    statuses_str() {
      return this.statuses.length > 0 ? this.statuses.join(',') : this.$t('SHOP_ORDER.ANY_STATUS');
    },
    count() {
      if (this.selection_option === 'SELECTION') {
        return this.shop_order_ids.length;
      }
      else {
        return this.fetch_count;
      }

    },
    ...mapGetters(['currentPeriodId']),
  },
  async mounted() {

  },
  methods: {
    formatsek(sum) {
      return format_sek(sum);
    },

    show() {
      this.showBatchOptionsModal = true;
    },
    close() {
      this.showBatchOptionsModal = false;
    },
    cancel() {
      this.$refs['modal'].hide();
      this.$emit('cancel');
    },
    save() {

    },

    get_shop_order_ids_from_option(selection_option) {

      const loader = this.$loading.show();

      if (selection_option === 'SELECTION') {

        this.fetch_count = this.shop_order_ids.length;

        loader & loader.hide();

        return;
      }

      axios
        .post(`/shop_order/pagination/count`, {
          options: {
            created_from: this.created_from,
            created_to: this.created_to,
            statuses: this.statuses
          }
        })
        .then(res => {
          if (res.status < 400) {
            this.fetch_count = res.data.count;
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_GET'));
          }

          loader & loader.hide();
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('PAGES.JOBS.UNABLE_GET'));

          loader & loader.hide();
        });
    }
  }
};
</script>

